// Company styles
.details_section {
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details_header_section {
  width: 1200px;
  padding-bottom: 80px;

  h1 {
    font-size: 64px;
  }
}

.award_header_section {
  width: 1200px;
  padding-bottom: 100px;

  h1 {
    font-size: 64px;
  }
}

.golden_standard_section {
  width: 1200px;
  display: flex;
  margin: 60px 0 150px;
  flex-direction: column;
}

.golden_standard_block {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin-bottom: 50px;
}

.golden_standard_info {
  display: flex;
  justify-content: space-between;

  .common_medium_span {
    color: rgba(159, 159, 159, 1);
    text-transform: uppercase;
  }

  h2 {
    width: 790px;
  }
}

.company_heads_section {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 150px 0 200px;
}

.company_heads_outer_block {
  width: 1200px;
  display: flex;
  gap: 80px;
}

.company_heads_info {
  display: flex;
  flex-direction: column;
  width: 550px;

  img {
    margin-top: 10px;
    margin-left: 15px;
  }

  h1 {
    margin: 30px 0 60px;
    color: rgba(21, 24, 27, 1);
    line-height: 110%;
  }

  .common_big_span {
    font-weight: 600;
    color: rgba(21, 24, 27, 1);
  }

  .common_big_span_grey {
    color: rgba(64, 69, 75, 1);
  }
}

.company_heads_block {
  display: flex;
  flex-direction: column;

  .common_big_span {
    margin-top: 30px;
  }
}

.our_history_section {
  width: 100%;
  border-top: 1px solid var(--addit-light-color);
  border-bottom: 1px solid var(--addit-light-color);
  padding-top: 150px;
  margin-bottom: 60px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our_history_section_d {
  width: 1200px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .common_medium_span {
    width: 100%;
    color: rgba(159, 159, 159, 1);
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  span {
    width: 40%;
  }
}

.our_history_header_section {
  width: 1200px;
  border-bottom: 1px solid var(--addit-light-color);
  padding-bottom: 30px;

  .common_medium_span {
    color: rgba(159, 159, 159, 1);
    text-transform: uppercase;
  }
}

.our_history_body_section {
  width: 1200px;
  display: flex;
  margin-top: 60px;
}

.our_history_left {
  min-width: 370px;
  margin-top: 83px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    font-size: 64px;
  }
}

.our_history_middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.circle_history {
  width: 14px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--global-dark-color);
}

.our_history_left_2014 {
  margin-bottom: 272px;
}

.our_history_left_2017 {
  margin-bottom: 325px;
}

.our_history_left_2019 {
  margin-bottom: 360px;
}

.our_history_left_2020 {
  margin-bottom: 250px;
}

.our_history_left_2021 {
  margin-bottom: 170px;
}

.circle_history_last {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--global-dark-color);
}

.line_history {
  width: 1px;
  background-color: var(--addit-light-color);
}

.line_1 {
  height: 433px;
}

.line_2 {
  height: 384px;
}

.line_3 {
  height: 409px;
}

.line_4 {
  height: 300px;
}

.line_5 {
  height: 212px;
}

.our_history_right {
  margin-top: 65px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;

  span {
    color: rgba(217, 217, 217, 1);
  }
}

.our_history_right_1 {
  margin-bottom: 188px;
}

.our_history_right_3 {
  margin-bottom: 160px;
}

.our_history_right_2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 127px;
  gap: 45px;
}

// Awards styles
.awards_section_d {
  width: 1200px;
  padding-bottom: 200px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .common_medium_span {
    width: 100%;
    color: rgba(159, 159, 159, 1);
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  span {
    width: 40%;
  }
}

.awards_body_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  border-bottom: 1px solid rgba(64, 69, 75, 1);
}

.awards_item_block {
  width: 100%;
  display: flex;
  gap: 45px;
  margin-top: 60px;
  flex-wrap: wrap;
  opacity: 0;
}

.awards_item_block_inner {
  gap: 30px;
  display: flex;
  justify-content: space-between;
}

.awards_item {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 25px;

  li {
    font-size: 14px;
    color: var(--addit-dark-color);
    margin-bottom: 10px;
  }
}

// Investment ideas styles
.investment_ideas_item {
  width: 1200px;
  margin: 0 0 60px;
  padding: 80px 0;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  border-bottom: 1px solid var(--addit-light-color);
}

.investment_ideas_left {
  width: 573px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.investment_ideas_left_info {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.investment_ideas_left_buttons {
  display: flex;
  gap: 30px;

  button {
    width: fit-content;
  }
}

.investment_ideas_right {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.can_get_block {
  display: flex;
  gap: 80px;
  border-bottom: 1px solid rgba(64, 69, 75, 1);
  padding-bottom: 40px;

  h1 {
    font-size: 36px;
  }
}

.can_get_info {
  display: flex;
  justify-content: space-between;
  gap: 100px;
}

.can_get_info_item {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.can_get_item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

//Service styles
.service_section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service_block {
  width: 100%;
  display: flex;
  margin-bottom: 60px;
  border-bottom: 1px solid var(--addit-light-color);
}

.service_left_item {
  height: 813px;
  overflow-y: hidden;
  width: 50%;
  border-right: 1px solid var(--addit-light-color);
}

.service_right_item {
  width: 600px;
  padding: 220px 0 0 80px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  h1 {
    font-size: 64px;
  }

  a {
    margin-top: 20px;
    max-width: fit-content;
  }
}

.circle {
  width: 4px;
  height: 20px;
  margin: 5px 10px;
  transform-origin: top center;
  animation: spin 4s ease-in infinite;
  transform: translateY(0);
  background-color: #54bbff;
}

@keyframes spin {
  0% {
    transform: rotate(0) scale(1) translateY(0);
    background-color: #54bbff;
  }
  50% {
    transform: rotate(30deg) scale(0.2) translateY(50px);
    background-color: #fda4ba;
  }
  100% {
    transform: rotate(0deg) scale(1) translateY(0);
    background-color: #54bbff;
  }
}

.circles {
  overflow: hidden;
  display: flex;
  gap: 20px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 0 1 100%;
}

.column:nth-child(1) .circle {
  animation-delay: 0ms;
}

/* = 0.1s */
.column:nth-child(2) .circle {
  animation-delay: 150ms;
}

/* = 0.1s */
.column:nth-child(3) .circle {
  animation-delay: 250ms;
}

/* = 0.1s */
.column:nth-child(4) .circle {
  animation-delay: 350ms;
}

/* = 0.1s */
.column:nth-child(5) .circle {
  animation-delay: 450ms;
}

/* = 0.1s */
.column:nth-child(6) .circle {
  animation-delay: 550ms;
}

/* = 0.1s */
.column:nth-child(7) .circle {
  animation-delay: 650ms;
}

/* = 0.1s */
.column:nth-child(8) .circle {
  animation-delay: 750ms;
}

/* = 0.1s */
.column:nth-child(9) .circle {
  animation-delay: 850ms;
}

/* = 0.1s */
.column:nth-child(10) .circle {
  animation-delay: 950ms;
}

/* = 0.1s */
.column:nth-child(11) .circle {
  animation-delay: 1050ms;
}

/* = 0.1s */
.column:nth-child(12) .circle {
  animation-delay: 1150ms;
}

/* = 0.1s */
.column:nth-child(13) .circle {
  animation-delay: 1250ms;
}

/* = 0.1s */
.column:nth-child(14) .circle {
  animation-delay: 1350ms;
}

/* = 0.1s */
.column:nth-child(15) .circle {
  animation-delay: 1450ms;
}

/* = 0.1s */
.column:nth-child(16) .circle {
  animation-delay: 1550ms;
}

/* = 0.1s */
.column:nth-child(17) .circle {
  animation-delay: 1650ms;
}

/* = 0.1s */
.column:nth-child(18) .circle {
  animation-delay: 1750ms;
}

// Contacts styles
.contacts_header_section {
  width: 1200px;
  padding-bottom: 80px;

  h1 {
    font-size: 64px;
  }
}

.contacts_menu_section {
  width: 1200px;
}

.contacts_menu_block {
  display: flex;
  font-weight: 200;
  font-size: 18px;
  color: var(--global-disabled-color);
  cursor: pointer;
  z-index: 2;
  gap: 20px;
}

.inactive_header {
  font-size: 36px;
  color: var(--addit-light-color);
}

.inactive_header:hover {
  color: var(--global-dark-color);
}

.active_header {
  color: var(--global-dark-color);
}

.active_contact_item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 30px 25px;
}

.active_contact_border {
  border-bottom: 1px solid var(--global-dark-color);
}

.contact_menu_border {
  height: 1px;
  width: 100%;
  margin-top: -1px;
  background-color: var(--addit-light-color);
}

.contact_details {
  margin-top: 60px;
}

@media only screen and (max-width: 1200px) {
  .details_header_section, .golden_standard_section, .company_heads_outer_block,
  .our_history_header_section, .our_history_body_section, .investment_ideas_item, .contacts_menu_section,
  .contacts_header_section {
    width: 90%;
  }
}

@media only screen and (min-width: 2560px) {
  .service_left_item {
    width: 922px;
  }
}

.under_line_awards_right {
  width: 30%;
  height: 1px;
  background-color: rgba(64, 69, 75, 1)
}

.under_line_awards_left {
  width: 70%;
  height: 1px;
  background-color: rgba(64, 69, 75, 1)
}

.awards_item_block[data-aos=fade-up] {
  transform: translate3d(0, 30px, 0);
}

.under_line_awards_left[data-aos=fade-up] {
  transform: scaleX(.1);
  transform-origin: left;
  transform-style: preserve-3D;
}

.under_line_awards_right[data-aos=fade-up] {
  transform: scaleX(.1);
  transform-origin: right;
  transform-style: preserve-3D;
}
