@media only screen and (min-width: 376px) {

  h2 {
    font-size: 20px !important;
  }

  .common_big_span_grey, .common_big_span {
    font-size: 16px !important;
  }

  .pin_info_header {
    .common_big_span {
      font-size: 18px !important;
    }
  }

  #language_section_sidebar, #user_section_sidebar {
    display: none;
  }

  .header_section_info {
    gap: 30px;
  }

  .general_block_header {
    bottom: 80px;

    h1 {
      font-size: 48px;
    }

    button {
      width: 160px;
      height: 160px;
      margin-top: 50px;
    }
  }


  .about_us_lower_section {
    padding-bottom: 120px;
  }

  .about_us_upper_section {
    gap: 20px;

    h2 {
      font-size: 20px;
    }
  }

  .about_us_lower_item {
    gap: 40px;
  }

  .awards_title {
    padding-bottom: 25px;
  }

  .crypto_inner_item_info {
    margin-right: 26px;
  }

  .crypto_inner_section {
    margin: 0 auto 120px;
  }


  .awards_section {
    margin: 120px 0 0;
  }

  .award_item {
    padding: 17px 25px;
  }

  .availability_info_numbers {
    gap: 90px;
  }

  .footer_menu_item {
    padding: 40px 20px;
  }

  .footer_menu_section {
    margin-bottom: 50px;
  }

  .general_footer_section {

    button {
      margin: 10px auto 0;
    }
  }

  .footer_rest {
    padding-bottom: 50px;
  }

  .modal_dialog_section_qa {
    .modal_dialog_block {
      width: calc(100% - 40px) !important;
    }
  }

  .modal_dialog_body_qa {
    padding: 30px 20px 90px !important;

    form {
      padding: 48px 0 50px !important;
    }
  }

  .not_found_section {
    padding-top: 136px;
    gap: 60px;
  }

  .not_found_block {
    gap: 40px;
    padding-bottom: 120px;
  }

  .not_found_info {
    h1 {
      font-size: 32px;
    }
  }

  .can_get_block {
    h1 {
      font-size: 32px;
    }
  }

  .awards_button_section {
    margin-top: 10px;

    button {
      font-size: 20px;
    }
  }

  .details_section {
    padding: 136px 0 0;
  }

  .award_header_section, .contacts_header_section, .details_header_section {
    padding: 0 20px 50px;

    h1 {
      font-size: 32px;
    }
  }

  .awards_item_block {
    margin-top: 20px;
    gap: 20px;
  }

  .awards_body_section {
    padding: 0 20px 120px;
  }

  .service_left_item {
    height: 382px;
  }

  .service_right_item {
    padding: 60px 20px 90px;
    gap: 20px;
  }

  .map_section_contact {
    margin-bottom: 120px;
  }

  .golden_standard_section {
    padding-top: 40px;
  }

  .golden_standard_info {
    gap: 20px;
  }

  .golden_standard_block {
    margin-bottom: 120px;
  }

  .company_heads_info {
    h2 {
      margin: 30px 0;
      font-size: 24px !important;
    }
  }

  .our_history_header_section {
    padding-bottom: 25px;
  }

  .company_heads_section {
    padding: 80px 20px 120px;
  }

  .our_history_section {
    padding: 120px 20px 20px 2px;
    margin-bottom: 60px;
  }

  .our_history_body_section {
    margin-top: 40px;
  }

  .our_history_info {
    padding-bottom: 100px;
  }

  .investment_ideas_item {
    padding: 60px 0;
    margin: 0 20px;
    gap: 40px;
  }

  .investment_ideas_left_buttons {
    margin-top: 20px;
  }

  .footer_ideas {
    margin-top: 60px;
  }

  .modal_dialog_body {
    padding: 40px 20px 80px;

    button {
      margin: 30px auto 0;
    }
  }

  .login_register_section {
    padding: 136px 20px 0;
    gap: 20px;
  }

  .contact_info_section {
    gap: 30px;
  }

  .login_register_block {
    padding: 40px 27px 0;

    .common_small_span {
      text-align: center;
    }
  }

  .login_section {
    margin-top: 26px;

    button {
      width: 90%;
      margin: auto;
    }
  }

  .extra_small_button, .small_button, .common_button {
    font-size: 14px !important;
  }

  .helping_links {
    margin-top: 30px;
  }

  .input_field_block {
    gap: 40px;
    margin-bottom: 40px !important;
  }

  .modal_dialog_header {
    span {
      font-size: 20px !important;
    }
  }

  .register_global_block {
    gap: 50px;
  }

  .register_block {
    padding: 60px 0 0;
  }

  .common_select_dropdown {
    left: 20px;
    right: 20px;
    width: unset !important;
  }

  .register_section {
    .common_big_span_grey {
      margin-top: 20px;
    }
  }

  .logo_first {
    width: 220px;
  }

  .logo_second {
    width: 370px;
  }

  .upload_file_block {
    width: 100%;
    padding: 0 20px;
  }

  .user_section, .user_section_payment {
    gap: 20px;
    padding: 80px 20px 0;
  }

  .user_account_overview {
    margin-top: 35px;
  }

  .account_details {
    margin-top: 40px;
  }

  .user_account_detail {
    gap: 30px;
  }

  .top_up_section {
    gap: 25px;
  }

  .account_balance {
    h1 {
      font-size: 24px;
    }
  }

  .transaction_table_header {
    th {
      font-size: 14px;
    }
  }

  .transaction_table_body_item {
    td {
      font-size: 16px;
    }
  }

  .modal_dialog_block {
    height: 320px;
  }

  .modal_dialog_body_big {
    padding: 60px 20px 80px;
  }

  .modal_dialog_body_big {
    div {
      gap: 20px;
    }

    .common_checkbox_block {
      margin-top: 40px;
      width: auto;
    }

    .common_button {
      margin: 0 27px;
      width: auto;
    }
  }

  .user_documents_item {
    margin-bottom: 60px;

    img {
      width: 45px;
    }
  }

  .user_documents_section {
    margin: 60px 20px 60px 0;
  }

  .user_menu_block {
    font-size: 12px;
  }

  .trade_section {
    padding: 136px 20px 0;
  }

  .trade_section_menu {
    margin-top: 40px;
  }

  .trade_section_switcher {
    margin-top: 60px;

    .common_medium_span_grey {
      padding-bottom: 18px;
      font-size: 18px;
    }
  }

  .trade_span {
    h5 {
      font-size: 14px;
    }

    span {
      font-size: 10px;
    }
  }

  .modal_dialog_upper {
    padding: 20px;
  }

  .modal_dialog_body_menu, .modal_dialog_body_input, .modal_dialog_body_check, .modal_dialog_body_input_l {
    margin: 0 20px;
  }

  .popup_button_group {
    gap: 15px;
    padding: 30px 20px 40px;
  }

  .trade_menu_border_item {
    margin-top: 15px;
  }

  .trade_menu_popup_outer {
    margin: 0 20px;
  }

  .trade_menu_popup:before {
    width: 74px;
    height: 33px;
    left: 2px;
    top: 5px;
    transform: translateX(0);
    transition: transform 400ms;
  }

  .menu_item_2:before {
    transform: translateX(74px);
    transition: transform 400ms linear;
  }

  .menu_item_3:before {
    transform: translateX(170px);
    transition: transform 400ms linear;
  }

  .menu_item_4:before {
    transform: translateX(259px);
    transition: transform 400ms linear;
  }

  .contact_menu_item {
    font-size: 20px;
  }

  .sidebar_menu_item {
    font-size: 18px;
  }
}

a, a:hover, a:focus, a:active, a:visited {
  text-decoration: none;
  color: #FFFFFF;
}

