//Login-registration styles
.login_register_section {
  height: 100vh;
  margin: auto;
  padding: 80px 0 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  text-align: center;

  .common_big_span_grey {
    width: 395px;
  }
}

.login_register_block {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 35px 25px;
  align-items: center;

  .common_small_span {
    width: 350px;
    margin-bottom: 10px;
    text-align: center;
  }

  .common_button {
    display: flex;
    justify-content: center;
    width: 350px;
  }
}

.login_section {
  width: 450px;
  padding: 64px 0 0;
  display: flex;
  flex-direction: column;

  button {
    margin: auto;
    width: 350px;
  }
}

.helping_links {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

// Registration styles
.register_section {
  width: 500px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin-bottom: 15px;
  }

  .common_big_span_grey {
    width: 450px;
  }
}

.register_fifth_section {
  width: 600px;

  .common_big_span_grey {
    margin-bottom: 24px;
    width: fit-content;
  }
}

.register_block {
  width: 100%;
  padding: 63px 0 0;
  display: flex;
  flex-direction: column;

  .common_button {
    max-width: 350px;
  }
}

.uploaded_files {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.uploaded_item {
  position: relative;
  width: 70px;
  height: 70px;
  padding: 8px;
  background-color: #1C1F23;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.register_forth_section {

  .register_forth_block {
    padding: 64px 0 0;
  }

  .common_big_span {
    max-width: 450px;
  }
}

.register_global_section {
  width: 100%;
  padding: 200px 0 0;
  display: flex;
  justify-content: center;
}

.register_global_block {
  width: 1200px;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.register_step_section {
  margin-right: 87px;
}

@media only screen and (max-width: 1100px) {
  .register_global_section {
    flex-direction: row;
    justify-content: flex-start;
  }

  .register_step_section {
    width: 100%;
    padding: 50px 0 100px;
  }
}

a, a:hover, a:focus, a:active {
  color: var(--addit-dark-color);
}

