// Common dropdown styles mobile
.common_dropdown_mobile {
  position: absolute;
  width: 35px;
  height: 80px;
  top: 23.5px;

  border-radius:50px;
  background-color: var(--language-dropdown-color);
  overflow-y: hidden;

  display: flex;
  justify-content: center;

  font-size: 12px;
  font-weight: 300;

  span {
    color: var(--global-dark-color);;
  }
}

.common_dropdown_mobile_l {
  height: 120px;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  top: 23.5px;
  padding-top: 20px;
}

.common_dropdown_cycle_mobile {
  position: relative;
  width: 35px;
  height: 35px;

  border-radius: 50%;

  background-color: var(--global-light-color);
  border: 1px solid #40454B;
  color: var(--global-dark-color);
  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  cursor: pointer;
}

.user_log_out_mobile {
  width: 15px;
  height: 15px;
  margin-top: 50px;
}

.user_log_out_mobile {
  background-repeat: no-repeat;
  background-position: 1px 0;
  cursor: pointer;
  background-image: url(/assets/images/shared/ui-kit/logOutIconLight.svg);
}
