//Login-registration styles

h1 {
  font-size: 32px;
}

.login_register_section {
  height: 100vh;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  button {
    max-width: 280px;
  }
}

.common_button {
  padding: 20px 40px;
}

.login_register_block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;

  .common_small_span {
    margin-bottom: 10px;
  }
}

.login_section {
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    max-width: 280px;
  }
}

.helping_links {
  margin: 20px 19px 0 28px;
  display: flex;
  justify-content: space-between;
}

// Registration styles
.register_section {
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    max-width: 275px;
  }

  .common_button {
    margin: auto;
    padding: 24px 40px;
    max-width: 320px !important;
  }
}

.register_fifth_section {
  width: 600px;

  .common_big_span_grey {
    margin-bottom: 24px;
    width: fit-content;
  }
}

.register_block {
  width: 100%;
  display: flex;
  flex-direction: column;

  .common_button {
    max-width: 350px;
  }
}

.uploaded_files {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

.uploaded_item {
  position: relative;
  width: 70px;
  height: 70px;
  padding: 8px;
  background-color: #1C1F23;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.register_forth_section {

  .register_forth_block {
    padding: 60px 0 0;
  }

  .common_big_span {
    max-width: 450px;
  }
}

.register_global_section {
  width: 100%;
  padding: 136px 0 100px;
  display: flex;
  justify-content: center;
}

.register_global_block {
  width: 100%;
  display: flex;
  padding: 0 20px;
  flex-direction: column;
}
