@import "assets/scss/desktop/common";
@import "assets/scss/mobile/common";
@import "assets/scss/shared/common";
@import "assets/scss/shared/global";
@import "assets/scss/shared/animations";

@media only screen and (min-width: 845px) {
  @import "assets/scss/desktop/details";
  @import "assets/scss/desktop/login-register";
  @import "assets/scss/desktop/user-account";
  @import "assets/scss/desktop/trade";
  @import "assets/scss/desktop/mediaQueryNotebook";
}
@media only screen and (max-width: 834px) {
  @import "assets/scss/mobile/tradeMobile";
  @import "assets/scss/mobile/detailsMobile";
  @import "assets/scss/mobile/mediaQuerySmall";
  @import "assets/scss/mobile/mediaQueryBig";
  @import "assets/scss/mobile/login-registerMobile";
  @import "assets/scss/mobile/user-accountMobile";
}


@font-face {
  font-family: Open_Sans;
  src: local('Open_Sans'), url(/assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Manrope;
  src: local('Manrope'), url(/assets/fonts/Manrope/static/Manrope-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Manrope-Medium;
  src: local('Manrope-Medium'), url(/assets/fonts/Manrope/static/Manrope-Medium.ttf) format('truetype');
}

@font-face {
  font-family: Manrope-Light;
  src: local('Manrope-Light'), url(/assets/fonts/Manrope/static/Manrope-Light.ttf) format('truetype');
}

@font-face {
  font-family: Raleway-light;
  src: local('Raleway-light'), url(/assets/fonts/Raleway/static/Raleway-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: Inter-Light;
  src: local('Inter-Light'), url(/assets/fonts/Inter/static/Inter-Light.ttf) format('truetype');
}


@font-face {
  font-family: Inter-Extra-Light;
  src: local('Inter-Extra-Light'), url(/assets/fonts/Inter/static/Inter-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: Cormorant;
  src: local('Cormorant'), url(/assets/fonts/Cormorant/Cormorant-Regular.ttf) format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'Manrope';
}

body {
  background-color: var(--global-light-color);
}

body.prevent_scroll {
  overflow-y: hidden;
}


@media screen and (-moz-windows-theme) {
  // Scrollbar styles
  ::-webkit-scrollbar {
    width: 21px;
  }

  ::-webkit-scrollbar-track {
    background: #23262B;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #40454B;
    border: 3px solid transparent;
    background-clip: content-box;
  }
}

a, a:hover, a:focus, a:active, a:visited {
  text-decoration: none;
}

